import React from 'react'

const NotFound404 = () => {
  return (
    <div className="container d-flex justify-content-center">
      <h3>404 Not Found</h3>
    </div>
  )
}

export default NotFound404
