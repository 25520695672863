import React, { Suspense, /*lazy*/ } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store, history } from "./store";
import { syncHistoryWithStore } from "react-router-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as ethutil from "./utils/ethutil";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/app.css";
import * as actions from "../src/actions";
import * as constants from "../src/constants";
import "./utils/^^";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
// import App from "./containers/App";
import Landing from "./containers/Landing"
import NotFound404 from "./components/not-found/NotFound404";
import Header from "./containers/Header";
// import Leaderboard from "./containers/Leaderboard";
import { loadTranslations } from "./utils/translations";

// For bundle splitting without lazy loading.
// const nonlazy = (component) => lazy(() => component);

// const Level = nonlazy(import("./containers/Level"));
// const Help = nonlazy(import("./containers/Help"));
// const Stats = nonlazy(import("./containers/Stats"));

Sentry.init({
  dsn: constants.SENTRY_DSN,
  debug: false,
  tunnel: "/errors",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: constants.VERSION,
});

const container = document.getElementById("root");
const root = createRoot(container);
let language = localStorage.getItem("lang");
let strings = loadTranslations(language);

root.render(
  <Provider store={store}>
    <Router history={syncHistoryWithStore(history, store)}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route path="*" element={<>
            <Header />
            {/* Deploy Window */}
            <div className="deploy-window-bg">
              <div className="stylized-container-border-wrap deploy-window">
                <main className="stylized-container">
                    <div className="sepolia-button-wrap">
                      <button
                        onClick={switchToSepolia}
                        className="stylized-container submission-button"
                      >
                        <span className="submission-button-text">{strings.switchToSepolia}</span>
                      </button>
                  </div>
                </main>
              </div>
            </div>

            <Routes>
              {/* <Route path={constants.PATH_APP} element={<App />} />
              <Route path={constants.PATH_HELP} element={<Help />} />
              <Route path={constants.PATH_LEVEL} element={<Level />} />
              <Route path={constants.PATH_STATS} element={<Stats />} />
              <Route path={constants.PATH_LEADERBOARD} element={<Leaderboard />} /> */}
              <Route path="*" element={<NotFound404 />} />
            </Routes>
          </>}/>
        </Routes>
      </Suspense>
    </Router>
  </Provider>
);

store.dispatch(actions.connectWeb3(window.ethereum));
if (!window.ethereum) {
  store.dispatch(actions.loadGamedata());
} else {
  window.ethereum.request({ method: "eth_chainId" }).then((res) => {
    store.dispatch(actions.setNetworkId(parseInt(res)));
    store.dispatch(actions.loadGamedata());
  })  
}


// change the network to Sepolia network
async function switchToSepolia() {

    //let elements = document.querySelectorAll('.progress-bar-wrapper');
    const deployWindow = document.querySelectorAll('.deploy-window-bg');
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${Number(constants.NETWORKS.SEPOLIA.id).toString(16)}` }],//if on wrong network giving option to switch to sepolia network.
      });
      deployWindow[0].style.display = 'none';
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: [{ chainId: `0x${Number(constants.NETWORKS.SEPOLIA.id).toString(16)}` }]
              },
            ],
          });
          deployWindow[0].style.display = 'none';
        } catch (addError) {
          if (addError.code === 4001) {
            //User has rejected changing the request
            //elements[0].style.display = 'none';
          }
          console.error("Can't add nor switch to the selected network")
        }
      } else if (switchError.code === 4001) {
        //User has rejected changing the request
        //if (elements[0]) elements[0].style.display = 'none';
      }
    }
}

/* needed to fix mobile responsiveness */
const documentHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--doc-height', `${window.innerHeight*0.999}px`)
 }
window.addEventListener("resize", documentHeight)
documentHeight()


// Post-load actions.
window.addEventListener("load", async () => {
  if (window.ethereum) {
    window.web3 = new constants.Web3(window.ethereum);
    try {
      await window.ethereum.request({ method: `eth_requestAccounts` });
    } catch (error) {
      console.error(error);
      console.error(`Refresh the page to approve/reject again`);
      window.web3 = null;
    }
  }

  if (window.web3) {
    ethutil.setWeb3(window.web3);
    ethutil.attachLogger();

    // Initial web3 related actions
    store.dispatch(actions.connectWeb3(window.web3));
    window.web3.eth.getAccounts(function (error, accounts) {
      let player;
      if (accounts.length !== 0 && !error) player = accounts[0];
      store.dispatch(actions.setPlayerAddress(player));
      store.dispatch(actions.loadEthernautContract());
      ethutil.watchAccountChanges((acct) => {
        store.dispatch(actions.setPlayerAddress(acct));
      }, player);
      ethutil.watchNetwork({
        gasPrice: (price) =>
          store.dispatch(actions.setGasPrice(Math.floor(price * 1.1))),
        networkId: (id) => {
          if (id !== store.getState().network.networkId)
            store.dispatch(actions.setNetworkId(id));
        },
        blockNum: (num) => {
          if (num !== store.getState().network.blockNum)
            store.dispatch(actions.setBlockNum(num));
        },
      });
    });
  }
});