import * as actions from '../actions';
import * as constants from '../constants';
import { deployRemainingContracts } from '../utils/contractutil';
import { deployAdminContracts } from '../utils/deploycontract';

// let elements = document.querySelectorAll('.progress-bar-wrapper');

// let wasOnWrongNetwork = false

// -- Get the localstorage key then fetch the corresponding value,
// -- IF This is a network supported by default OR there is a localstorage key indicating contracts have been locally deployed THEN continue as normal
// -- ELSE notify the user that they need to deploy on this network to play the game or switch to a network that has the game, then run deployment script and set returned addresses to localstorage
const setNetwork = store => next => action => {
  window.localdeploy = deployAdminContracts; //TODO later remove refrence to contract from windows
  window.deployAllContracts = deployRemainingContracts;
  if (action.type !== actions.SET_NETWORK_ID) return next(action) //we need to reload the window here
  // elements = document.querySelectorAll('.progress-bar-wrapper');

  if (Number(action.id) !== Number(constants.NETWORKS.SEPOLIA.id) && (Number(action.id) !== Number(1337))) {
    const deployWindow = document.querySelectorAll('.deploy-window-bg');
    if (deployWindow[0]) deployWindow[0].style.display = 'flex';
  }

  //This will trigger reload if the network is changed 
  if (store.getState().network.networkId !== undefined && store.getState().network.networkId !== action.id) {
    // elements[0].style.display = 'flex';
    document.location.replace(`${document.location.origin}${document.location.pathname.indexOf('level') > 0 ? '' : document.location.pathname}`)
    return;
  }

  next(action)
}

export function onPredeployedNetwork(id) {
  let onRightNetwork = false;
  let allNetworkIds = Object.keys(constants.ID_TO_NETWORK).filter(id => constants.ID_TO_NETWORK[id] !== constants.NETWORKS.LOCAL.name).map((key) => Number(key))
  onRightNetwork = allNetworkIds.includes(Number(id));
  return onRightNetwork;
}


export default setNetwork
