import React from "react";
import onClickOutside from 'react-onclickoutside'
import { connect } from "react-redux";
import { withRouter } from "../hoc/withRouter";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../actions";
import * as constants from "../constants";
import { loadTranslations } from "../utils/translations";
import PropTypes from "prop-types";
// import { ProgressBar } from "react-loader-spinner";
import { svgFilter } from "../utils/svg";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dark: false,
      lang: localStorage.getItem("lang"),
      chainId: 0,
      activeDropdown: null,
      multiDDOpen: false,
    };

    if (this.props.web3) {
      window.ethereum.request({ method: "eth_chainId" }).then((id) => {
        this.setState({ chainId: Number(id) });
      });
    }
  }

  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  setActiveTab(tabIndex) {
    const { activeDropdown } = this.state;
    const newState =
      activeDropdown === tabIndex && activeDropdown ? null : tabIndex;
    this.setState({ activeDropdown: newState });
  }

  getDDClassName(tabdcurrentTabIndex) {
    const { activeDropdown } = this.state;
    const className = tabdcurrentTabIndex === activeDropdown ? "show" : "hide";
    return className;
  }

  toggleDropdownState() {
    this.setState({
      multiDDOpen: !this.state.multiDDOpen,
    });
  }

  closeDropdown() {
    if (!this.state.multiDDOpen) return;
    this.setState({
      multiDDOpen: false,
    });
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps && this.props.location !== prevProps.location) {
      let elements = document.getElementsByClassName("level-tile");
      if (elements.length !== 0) {
        for (let i = 0; i < elements.length; i++) {
          let element = elements[i];
          if (element && element.style)
            element.style.filter = this.state.dark ? svgFilter() : null;
        }
      }

      // Change The Ethernaut logo
      var theEthernaut = document.getElementById("the-ethernaut");
      if (theEthernaut && theEthernaut.style)
        theEthernaut.style.filter = this.state.dark ? svgFilter() : null;

      // Change Arrow
      let isArrowInPage = document.getElementById("arrow");
      if (isArrowInPage && isArrowInPage.style)
        isArrowInPage.style.filter = this.state.dark ? svgFilter() : null;

      // Change all custom images
      var imageElements = document.getElementsByClassName("custom-img");
      if (imageElements.length !== 0) {
        for (let i = 0; i < imageElements.length; i++) {
          let element = imageElements[i];
          if (imageElements.length === 0) element = imageElements;
          if (element && element.style)
            element.style.filter = this.state.dark ? svgFilter() : null;
        }
      }
    }
  }


  handleClickOutside = () => {
    this.closeDropdown();
  }

  render() {
    let strings = loadTranslations(this.state.lang);

    const ddOpen = Boolean(this.state.multiDDOpen);
    return (
      <div onClick={() => this.closeDropdown()}>

        <center>
          <header>
            <img className="title-text" alt="" src='../../imgs/landing/the-ethernaut-games-text-vector.svg'/>

            {/* ---- Multi Dropdown Container */}
            <div
              onClick={(e) => e.stopPropagation()}
              className="multi-dropdown"
            >
              {/* dropdown icon */}
              <div
                onClick={() => this.toggleDropdownState()}
                className="multi-dropdown__icon"
              >
                <i className="fas fa-bars"></i>
              </div>
              {/* dropdown icon */}
              {/* dropdown content */}
              <ul
                className={`multi-dropdown__dropdown ${
                  ddOpen ? "--open" : "--closed"
                }`}
              >
                <div className="dropdown-pill --left">
                  <div>
                    <Link
                      to={
                        window.location.pathname !== constants.PATH_APP
                          ? constants.PATH_APP
                          : constants.PATH_HELP
                      }
                      onClick={ () => this.handleClickOutside()}
                    >
                        {window.location.pathname !== constants.PATH_APP ? (
                          <div className="filled-icon home-icon" >
                          <>
                            <i className="fas fa-home"></i>
                          </>
                          </div>
                        ) : (
                          <div className="filled-icon question-icon">

                          <>
                            <i className="fas fa-question"></i>
                          </>
                          </div>
                        )}
                    </Link>

                    {
                      !!this.props.web3 && (
                          <Link
                            to={
                              window.location.pathname === constants.PATH_LEADERBOARD
                                ? constants.PATH_HELP
                                : constants.PATH_LEADERBOARD
                            }
                            onClick={ () => this.handleClickOutside()}
                          >
                              {window.location.pathname === constants.PATH_LEADERBOARD ? (
                                <div className="filled-icon question-icon">

                                <>
                                  <i className="fas fa-question"></i>
                                </>
                                </div>
                              ) : (
                                <div className="filled-icon leaderboard-icon">

                                <>
                                  <i className="fa-sharp fa-solid fa-ranking-star"></i>
                                </>
                                </div>
                              )}                            
                          </Link>
                      )}
                  </div>
                </div>
              </ul>
              {/* dropdown content */}
            </div>
          </header>
          {!this.props.web3 && (
            <div
              style={{ backgroundColor: "#eddfd6", border: "none" }}
              className="alert alert-warning"
            >
              <strong>{strings.warning}! </strong>
              <span>{strings.warningMessage}</span>
            </div>
          )}
        </center>

        {/* <div className="lines">
          <center>
            <hr className="top" />
          </center>
          <center>
            <hr className="top" />
          </center>
        </div> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    web3: state.network.web3,
    allLevelsCompleted: state.player.allLevelsCompleted,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setLang: actions.setLang,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(onClickOutside(Header)));