import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "../hoc/withRouter";
import "../styles/landing.css";
import HubspotLandingForm from "../components/HubspotLandingForm/HubspotLandingForm";


class Landing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormActive: false,
    }

  }

  render() {

    return (
     
        <main>
            <div className="appcontainer">
                  {this.state.isFormActive?
                    <div className="stylized-container-border-wrap form-container-wrap">
                        {/*Rules and Registration Form*/}
                      <div className="stylized-container form-container">
                        <span className="close-button" onClick={() => this.setState({...this.state, isFormActive: false})}>✖</span>
                        <h1>Welcome</h1>
                        <h2>Intro</h2>
                        <h5>Welcome to the futuristic realm of "The ethernaut games" In this heart-pounding video game experience, you'll assume the role of a skilled space hacker, venturing into the vast reaches of the Ethereum blockchain. As the galaxy's most sought-after digital renegade, your mission is to compete in an electrifying capture the flag event. With your cybernetic enhancements and unparalleled hacking skills, breach fortified defenses, outsmart rival hackers, and seize control of valuable Ether flags. Brace yourself for a thrilling journey through a virtual cosmos where every move counts, and the fate of the blockchain lies in your hands. Prepare to hack the universe like never before.</h5>
                        <h2>Rules</h2>
                        <h5> Any attack to Ethernaut infrastructure is forbidden, the attacks must be limited to only to ctf contracts</h5>
                        <h2>Achieve your POAP(s)</h2>
                        <h5>Prepare yourself for the thrilling journey of a galactic hacker, as you conquer each level and earn the Participation POAPs. But it doesn't end there. The most elite hackers will vie for the prestigious titles associated with four remarkable POAPs.

                          <ul className="poaps-ul">
                            <li>Participation POAP’s - 4 Total, 1 for each level</li>
                            <li>Prestigious POAP #1 - Title: "First Blood"</li>
                            <li>Prestigious POAP #2 - Title: "Most Deadly" or "Most Creative" or "Most Professional"</li>
                            <li>Prestigious POAP #3 - Title: "Most Honorable"</li>
                            <li>Prestigious POAP #4 - Title: "License to Hack"</li>
                          </ul>
                          </h5>
                          
                          <h2>Register to be eligible and appear on the leaderboard</h2>
                            <span className="hubspot-form">                         
                            <HubspotLandingForm
                              portalId='7795250'
                              formId='d3350730-da69-48ca-bfba-7ac71ead5bd8'
                              region='na1'
                              onSubmit={() => console.log('Submit!')}
                              loading={<div>Loading...</div>}
                              address
                            />
                            </span>
                          <div>
                          </div>
                            <div className="stylized-container-border-wrap submission-button-wrap">
                              <button
                                onClick={() => this.props.navigate(`/app`)}
                                className="stylized-container submission-button"
                              >
                                <span className="submission-button-text">Play Now</span>
                              </button>
                            </div>
                      </div>
                    </div>
                  
                  :
                <div className="landing-container">
                  {/*Landing */}
                  <div className="title-wrapper">
                    <img className="oz-logo" alt="" src='../../imgs/landing/openzeppelin-logo-vector.svg'/>
                    <div className="title-separator"/>
                    <img className="title-text-landing" alt="" src='../../imgs/landing/the-ethernaut-games-text-vector.svg'/>
                  </div>
                  <div className="main-container">
                      <div className="left-container">      
                            <span className="subtitle-text">A ZERO GRAVITY HACKING EXPERIENCE</span>
                            <span className="description-text">What is open and permissionless is not always secure. Do you have what it takes to hack your way back home? The Ethernaut Games are coming soon…</span>
                            <div className="stylized-container-border-wrap submission-button-wrap">
                              <button
                                onClick={() => this.setState({...this.state, isFormActive: true})}
                                className="stylized-container submission-button"
                              >
                                <span className="submission-button-text">Begin the adventure</span>
                              </button>
                            </div>
                      </div>
                      <div className="right-container">
                        <div className="purple-halo"/>
                        <div className=" yellow-halo"/>
                        <img alt="" className="landing-image" src='../../imgs/landing/epic-bg.png'/>
                      </div>
                  </div>
                </div>
                }
            </div>
            
        </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    levels: state.gamedata.levels,
    completedLevels: state.player.completedLevels,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Landing));


