import React from 'react'

class HubspotLandingForm extends React.Component {
	constructor(props) {
		super(props)
		window.ethereum.request({ method: 'eth_requestAccounts' }).then(address => {this.setState({...this.state,userAddress: address[0]})})
		
		this.state = {
			loaded: false
		}
		this.createForm = this.createForm.bind(this)
		this.findFormElement = this.findFormElement.bind(this)
		this.onFormReady = this.onFormReady.bind(this)

		
	}
	createForm() {
		if (window.hbspt) {
			// protect against component unmounting before window.hbspt is available
			if (this.el === null) {
				return
			}
			let props = {
				...this.props
			}
			delete props.loading
			delete props.onSubmit
			let options = {
				...props,
				target: `#${this.el.getAttribute(`id`)}`,
				onFormReady: ($form) => this.onFormReady($form),
				onSubmit: ($form) => {
					// ref: https://developers.hubspot.com/docs/methods/forms/advanced_form_options
					var formData = $form.serializeArray();
					console.log(formData);
				}
			}
			window.hbspt.forms.create(options)
			return true
		}
		else{
			setTimeout(this.createForm, 1)
		}
	}
	onFormReady(form){
		if(this.state.userAddress){
			let addressInput = form.querySelector("#ethernaut_address-d3350730-da69-48ca-bfba-7ac71ead5bd8");
			addressInput.style.pointerEvents = 'none';
			addressInput.value = this.state.userAddress;
		}
		console.log(form);

	}
	findFormElement(){
		// protect against component unmounting before form is added
		if (this.el === null) {
			return
		}
		let form = this.el.querySelector(`iframe`)
		if(form){
			this.setState({ loaded: true })
		}
		else{
			setTimeout(this.findFormElement, 1)
		}
	}
	componentDidMount() {
		if (window.hbspt) {
			this.createForm()
			this.findFormElement()
		}
	}
	componentWillUnmount() {
	}

	render() {
		
		return (
			<div>
				<div
					ref={el => this.el = el}
					id={`HubspotLandingForm`}
					style={{ display: this.state.loaded ? 'block' : 'none'}}
					/>
				{!this.state.loaded && this.props.loading}
			</div>
		)
	}
}

export default HubspotLandingForm